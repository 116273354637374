<template>
  <div>
    <b-card
      class="mt-3"
      :header="header"
      inline
      v-bind:class="{ buy: isItBuy, sell: isItSell }"
    >
      <b-table
        hover
        :items="clients"
        :fields="newField"
        :style="{ width: numberOfColumns * 25 + 'rem' }"
      >
        <template v-slot:cell(bank_id)="row">
          <span>{{ row.item.bank_id }}</span>
        </template>
        <template v-slot:cell(client_id)="row">
          <span>{{ row.item.client_id }}</span>
        </template>
        <template v-slot:cell(bbg_ticker)="row">
          <span>{{ row.item.bbg_ticker }}</span>
        </template>
        <template v-slot:cell()="data">
          <b-input-group append="%" inline>
            <b-form-input
              inline
              class="inputComponent"
              type="number"
              v-model="data.item[data.field.selector]"
              @change="updateAssets()"
            >
            </b-form-input>
          </b-input-group>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "WizardComponent3",
  props: {
    param: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      numberOfColumns: 1,
    };
  },
  computed: {
    header() {
      let trade = this.isItBuy ? "BUY" : "SELL";
      return (
        "Enter the amount of each security you want to " +
        trade +
        ", as % of the total client value"
      );
    },

    isItBuy() {
      return this.param.buy;
    },
    isItSell() {
      return this.param.sell;
    },
    clients() {
      // rows
      //road to assets format, first each clients is sth like {client_id: name , active:1}
      let properties = this.securities.map((a) => a[this.param.columnKey]);
      let prop = properties.reduce((o, key) => ({ ...o, [key]: null }), {}); //here we create an object with securities as properties with value:null {security1:null , security2:null ...}
      let clientsWithProperties = this.$store.state[this.param.rowStore].map(
        (element) => {
          return { ...element, ...prop }; // here we combine each client with the last object we created. [{client_id: name , active:1, security1:null ...},{client2 ...}..{}]
        }
      ); // the array ClientWithProperties is display on the table and bind to the inputs so the value can be changed

      return clientsWithProperties;
    },
    assets() {
      //inicialmente tengo [ cliente1ConPropiedadesDeMas , cliente2ConProp ...]
      // item = elemento de array  por ej cliente1ConPropiedadesDeMas
      //obj va a ser el objeto final que quiero tener donde obj = {cliente1:{security1:1 ,sec2;2 ...}, cliente2:{}....}
      const arrayToObject = (array, keyField) =>
        array.reduce((obj, item) => {
          //mi item inicial tiene propiedades de mas , por eso creo un obj llamado prop que tiene solo a las securities como propiedades prop= {security1:1, security2:null }
          // y los valores de estas propiedades salen de "item" o clienteConPropiedades de mas
          let properties = this.securities.map((a) => a[this.param.columnKey]);
          let prop = properties.reduce(
            (o, key) => ({ ...o, [key]: item[key] }),
            {}
          );

          //con cada item del array inicial voy a crear un Obj  y nombrarlo con el valor de una de sus propiedades , en este caso lo llamo KeyField y voy a elegir el client_id
          // este objecto 1 que reemplaza a item va a ser el "prop" que cree anteriormente, pero con el ultimo ".reduce" elimino los valores "falsy", como null o "0"
          obj[item[keyField]] = Object.entries(prop).reduce(
            (a, [k, v]) => (v ? ((a[k] = v), a) : a),
            {}
          );
          return obj;
        }, {});
      return arrayToObject(this.clients, [this.param.rowKey]);
    },

    securities() {
      let securitiees = this.$store.state[this.param.store];
      this.numberOfColumns = securitiees.length;
      return securitiees;
    },
    newField() {
      //columns , need to change to be variables
      let firstColumn = [
        {
          key: this.param.firstColumnKey,
          label: "",
          thClass: "laprimera",
          colspan: 1,
        },
      ];
      let newFields = this.securities.map((element) => {
        return {
          key: element[this.param.columnKey],
          //         label: element[this.param.columnKeyDisplay],
          selector: element[this.param.columnKey],
        };
      });
      return firstColumn.concat(newFields);
    },
    SellPost() {
      if (this.param.criteria == "portfolio") {
        let ObjectToPost = this.clients.map((person) => ({
          [`${person.bbg_ticker}`]: {
            value: "las demas propierdades",
            split: this.securities.reduce((c, v) => {
              c[v.bank_id] = c[v.bank_id] || {}; //Init if bank property does not exist
              c[v.bank_id][v.client_id] = c[v.bank_id][v.client_id] || {}; //Init if client_id property does not exist
              c[v.bank_id][v.client_id][v.portfolio_id] =
                person[v.portfolio_id]; //Add portfolio property with null value
              return c;
            }, {}),
          },
        }));

        return Object.assign({}, ...ObjectToPost);
      } else if (this.param.criteria == "bank") {
        let ObjectToPost = this.clients.map((person) => ({
          [`${person.bbg_ticker}`]: {
            value: "las demas propierdades",
            split: this.securities.reduce((c, v) => {
              c[v.bank_id] = person[v.bank_id]; //Init if bank property does not exist

              return c;
            }, {}),
          },
        }));

        return Object.assign({}, ...ObjectToPost);
      } else if (this.param.criteria == "client") {
        let ObjectToPost = this.clients.map((person) => ({
          [`${person.bbg_ticker}`]: {
            value: "las demas propierdades",
            split: this.securities.reduce((c, v) => {
              //  c[v.bank_id] = c[v.bank_id] || {};
              //  c[v.bank_id][v.client_id] = person[v.client_id] || {};
              c[v.client_id] = person[v.client_id];
              return c;
            }, {}),
          },
        }));

        return Object.assign({}, ...ObjectToPost);
      }
    },
  },
  methods: {
    updateAssets() {
      this.param.sell
        ? this.$store.dispatch("loadSellAssets", this.SellPost)
        : this.$store.dispatch("loadAssets", this.assets);
    },

    arrayToObject(array, keyField) {
      array.reduce((obj, item) => {
        obj[item[keyField]] = item;
        return obj;
      }, {});
    },
  },
  mounted() {},
};
</script>

<style scoped>
.card-header {
  font-weight: 500;
  font-size: 1.4rem;
}
.buy > .card-header {
  background-color: var(--buy-color);
  color: aliceblue;
}
.sell > .card-header {
  background-color: var(--sell-color);
  color: aliceblue;
}
.inputComponent {
  max-width: 90px;
  border-top: 1px solid #ced4da;
  border-left: 1px solid #ced4da;
}
.inputComponent > .input-group-append > .input-group-text {
  background-color: transparent;
}
</style>
