<template>
  <ShadowCard
    :param="{
      name: 'Buy Wizard',
    }"
  >
    <form-wizard
      color="var(--buy-color)"
      shape="tab"
      :hideButtons="buttonDisplay"
      :ref="'buywizardref'"
      @update:startIndex="updateWizardIndex()"
      title=""
      subtitle=""
    >
      <tab-content title="Select Securities" :before-change="validate1">
        <WizardComponent v-bind:param="parambuy" />
      </tab-content>
      <tab-content title="Select Clients" :before-change="validate2">
        <WizardComponent v-bind:param="param2buy" />
      </tab-content>
      <tab-content title="Define Exposure" :before-change="validate3">
        <WizardComponent3 v-bind:param="paramComponent3" />
      </tab-content>
      <tab-content title="Split Trade" :before-change="validate4">
        <Wizard-component4 v-bind:param="param4buy" />
      </tab-content>
      <tab-content title="Get Trade Order">
        <WizardComponent5 />
      </tab-content>
    </form-wizard>
  </ShadowCard>
</template>

<script>
import ShadowCard from "../../../presentationalComponents/ShadowCard.vue";
import WizardComponent from "./WizardComponent.vue";
import {
  getSecurities,
  getClients,
  getCustomPortfolio,
  postWizard,
} from "../../../services/api.js";
import WizardComponent3 from "./WizardComponent3.vue";
import WizardComponent4 from "./WizardComponent4.vue";
import WizardComponent5 from "./WizardComponent5.vue";
export default {
  name: "BuyWizard",
  components: {
    WizardComponent,
    WizardComponent3,
    WizardComponent4,
    WizardComponent5,
    ShadowCard,
  },
  data() {
    return {
      parambuy: {
        buy: true,
        header: "What securities do you want to BUY?",
        fields: ["index", "name", "bbg_ticker", "actions"],
        getter: getSecurities(),
        label: "name",
        setter: "SECURITIES",
        customOption: true,
        myFilter: (option, label, search) => {
          //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
          let temp = search.toLowerCase();
          return (
            option.bbg_ticker.toLowerCase().indexOf(temp) > -1 ||
            option.name.toLowerCase().indexOf(temp) > -1
          );
        },
      },
      param2buy: {
        buy: true,
        header: "On which clients do you want to trade?",
        fields: ["index", "client_id", "actions"],
        getter: getClients(),
        label: "client_id",
        setter: "CLIENTS",
        myFilter: (option, label, search) => {
          //by default in an array of objects, vue-select will look for a label "key" (only one),the custom filter is used to determine if the provided option matchs the current search text, in order to determine if the option should be displayed.
          let temp = search.toLowerCase();
          return option.client_id.toLowerCase().indexOf(temp) > -1;
        },
      },
      paramComponent3: {
        buy: true,
        store: "form_securities", // store.state  data(used for columns)
        firstColumnKey: "client_id", // must be a property of rows
        columnKey: "bbg_ticker", // Key of columns that will be assign
        columnKeyDisplay: "ticker", // key of columns that will be displayed
        rowStore: "form_clients", // store data (used for rows )
        rowKey: "client_id", // row.key that will be assigned an object with ColumnKeys
      },
      param4buy: {
        buy: true,
      },
      portfolio_list: {},
      step: 0,
    };
  },
  computed: {
    securities() {
      return this.$store.state.form_securities;
    },
    clients() {
      return this.$store.state.form_clients;
    },
    assets() {
      return this.$store.state.form_assets;
    },

    counter() {
      return this.$store.state.form_counter;
    },
    post_object() {
      return this.$store.state.form_post;
    },
    form_porfolioList_updated() {
      return this.$store.state.form_porfolioList_updated;
    },
    buttonDisplay() {
      //array that will return true/false to display buttons , the step/element from array will be determined by beforeChange()

      let arrayStep = [];

      arrayStep[0] = !this.securities.length;
      arrayStep[1] = !this.clients.length;
      arrayStep[2] = !(Object.entries(Object.values(this.assets)).length !== 0);
      arrayStep[3] = !Boolean(
        Object.keys(this.form_porfolioList_updated).length
      );

      return arrayStep[this.step];
    },
  },
  created: function () {
    // On create it cleans the store variables related to buy/sell

    this.$store.dispatch("cleanWizard");
  },
  methods: {
    updateWizardIndex() {
      this.$nextTick(() => {
        this.step = this.$refs.buywizardref.activeTabIndex;
      });
    },
    validate1() {
      if (this.securities.length) {
        return true;
      }
    },
    validate2() {
      if (this.clients.length) {
        this.clients
          .map((a) => a.client_id)
          .forEach((element) => {
            getCustomPortfolio(`${element}`).then((response) => {
              this.portfolio_list[element] = response.data.data
                .map((a) => a.portfolio_id)
                .reduce((o, key) => ({ ...o, [key]: 0 }), {});
              this.$store.commit("SET_FORM_PORTFOLIOLIST", this.portfolio_list);
            });
          });
        return true;
      }
    },
    validate3() {
      if (Object.entries(Object.values(this.assets)).length !== 0) {
        //Assets is an object containing objects, here we check if those nested objects are empty
        // this.step=3
        return true;
      }
    },
    validate4() {
      this.$store.dispatch("form_counter");
      let a = this.form_porfolioList_updated;
      if (this.$store.state.form_counter.every((elem) => elem == 100)) {
        this.$store.commit("SET_POST_OBJECT");

        var bodyFormData = new FormData();
        bodyFormData.set("data", this.post_object);
        postMockApi("buy", this.post_object)
          .then((response) => {
            if (response.status === 200) {
              //this.makeToast(  "success", response.status,"Account created successfully" );

              this.$store.commit("SET_FORM_RESPONSE", response.data);
            }
          })
          .catch((response) => {
            {
              this.makeToast("danger", "error", "oops! something went wrong");
            }
          });

        return Boolean(Object.keys(a).length);
      }
    },
  },
};
</script>

<style>
div.checked {
  color: black;
  background-color: var(--buy-color);
}

span.stepTitle.active {
  font-size: 25px;
}
</style>
